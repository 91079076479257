import * as Config from '../configs/server.configs';

const endPoint = Config[process.env.NODE_ENV].server + '/api/';
const bankEndPoint = Config[process.env.NODE_ENV].bankEndPoint;
const endPointOryton = Config[process.env.NODE_ENV].orytonEndPoint;
const endPointAccount = Config[process.env.NODE_ENV].ACCOUNT_API + '/api';
const endPointEHF = Config[process.env.NODE_ENV].ehfEndpoint + '/api';
const endPointTask = Config[process.env.NODE_ENV].taskEndpoint + '/api';
const endpointTrackingBank = Config[process.env.NODE_ENV].trackingBankEndpoint + '/api';
const endpointPaymentStatistic = Config[process.env.NODE_ENV].statisticsEndpoint + '/api';
const endpointMail = Config[process.env.NODE_ENV].mailServerEndpoint;
const endpointZtl = Config[process.env.NODE_ENV].ztlEndpoint + '/api';
const endpointVoucherAI = Config[process.env.NODE_ENV].voucherAIEndpoint + '/api';

export const api = {
  task: {
    get: endPointTask + '/v1/tasks',
    add: endPointTask + '/v1/tasks',
    update: endPointTask + '/v1/tasks/:id',
    sortIndex: endPointTask + '/v1/tasks/:id/sort-index',
    getById: endPointTask + '/v1/tasks/:id',
    getAttachments: endPointTask + '/v1/tasks/:id/attachments',
    addAttachments: endPointTask + '/v1/tasks/:id/attachments',
    sendAttachments: endPointTask + '/v1/tasks/:id/attachments/send',
    deleteAttachments: endPointTask + '/v1/tasks/:id/attachments/:fileId',
    getComments: endPointTask + '/v1/tasks/:id/comments',
    addComment: endPointTask + '/v1/tasks/:id/comments',
    updateComment: endPointTask + '/v1/tasks/:id/comments/:commentId',
    getCommentById: endPointTask + '/v1/tasks/:id/comments/:commentId',
    deleteComment: endPointTask + '/v1/tasks/:id/comments/:commentId',
    getLabelS2: endPointTask + '/v1/tasks/:id/lables2Tasks',
    updateLabelS2: endPointTask + '/v1/tasks/:id/lables2Tasks',
    assignUsers: endPoint + 'companyUsers/fetchByCompanyId',
    simpleUpload: endPointTask + '/v1/files/simple-upload',
    getHistories: endPointTask + '/v1/tasks/:id/histories',
    getNotifications: endPointTask + '/v1/tasks/by-user/notifications',
    markAll: endPointTask + '/v1/tasks/by-user/notifications/mark-all',
    readed: endPointTask + '/v1/tasks/by-user/notifications/:id/readed',
    countPending: endPointTask + '/v1/tasks/:companyId/count-pending',
    dashboard: endPointTask + '/v1/tasks/:companyId/task-pending-latest',
    static: endPointTask + '/v1/task-static',
    getOverview: endPointTask + '/v1/task-overview',
    getCreators: endPointTask + '/v1/task/get/creator',
    getCompanies: endPointTask + '/v1/task/get/companies',
  },
  taskLabel: {
    get: endPointTask + '/v1/labels',
    getLabelInCompany: endPointTask + '/v1/labels/get/company',
    add: endPointTask + '/v1/labels',
    update: endPointTask + '/v1/labels/:id',
    getById: endPointTask + '/v1/labels/:id',
    delete: endPointTask + '/v1/labels/:id',
  },
  taskTemplate: {
    get: endPointTask + '/v1/templates',
    add: endPointTask + '/v1/templates',
    update: endPointTask + '/v1/templates/:id',
    getById: endPointTask + '/v1/templates/:id',
    delete: endPointTask + '/v1/templates/:id',
  },
  voucher: {
    get: endPoint + 'vouchers',
    add: endPoint + 'vouchers/addByWeb',
    addPayment: endPoint + 'vouchers/addPayment',
    removePayment: endPoint + 'vouchers/removePayment', // balance
    update: endPoint + 'vouchers/:id',
    delete: endPoint + 'vouchers/:id',
    getStatistic: endPoint + 'vouchers/getStatisticByYear',
    getYears: endPoint + 'vouchers/getYears',
    getByMonth: endPoint + 'vouchers/getByMonth',
    updatePayment: endPoint + 'vouchers/payment/',
    updateMissing: endPoint + 'vouchers/missing/',
    fetchAll: `${endPoint}vouchers/fetchAll`,
    crawlVoucherEmail: `${endPoint}vouchers/crawlVoucherEmail`,
    getVoucherBalance: `${endPoint}vouchers/getVoucherBalance`,
    getMissingSummary: `${endPoint}vouchers/getMissingSummary`,
    getForwardedVoucher: `${endPoint}vouchers/getForwardedVoucher`,
    fetchAdminVoucher: `${endPoint}vouchers/fetchAdminVoucher`,
    updatePaymentStatus: endPoint + 'vouchers/updatePaymentStatus',
    getVoucherSuggestInfo: `${endPoint}vouchers/get-voucher-suggest-info`,
    deleteVoucherBySelectRowKeys: `${endPoint}vouchers/delete-list`,
  },
  voucherAI: {
    addSuggestion: `${endpointVoucherAI}/voucher-ai/get-suggestion`,
  },
  voucherPayments: {
    // addToPaymentList: endPoint + 'voucherPayment/addToPaymentList', // add to payment list to send to bank for payment width code
    // payToBank: endPoint + 'voucherPayment/payToBank',
    fetchAll: endPoint + 'voucherPayments', // add to payment list to send to bank for payment width code
    save: endPoint + 'voucherPayments',
    update: endPoint + 'voucherPayments/:uuid',
    getByVoucherId: endPoint + 'voucherPayments/getByVoucherId',
    getByPaymentsUUID: endPoint + 'voucherPayments/get-by-payments-uuid',
    updateVoucherPaymentsStatus: endPoint + 'voucherPayments/update-voucher-payments-status',
    // saveList: endPoint + 'voucherPayments/save-list',
    checkAllowPayment: endPoint + 'voucherPayments/check-allow-payment',
    handleAfterCreateAsice: endPoint + 'voucherPayments/handle-after-create-asice',
    updateByPaymentsIds: endPoint + 'voucherPayments/update-by-paymentsIds',
    getStatistic: endPoint + 'voucherPayments/statistics',
    addUnpaidEhfVouchersToPaymentList: endPoint + 'voucherPayments/add-unpaid-ehf-vouchers-to-payment-list/:companyId',
    getPaymentActions: endPoint + 'voucherPayments/:companyId/get-payment-actions',
    editInternalTransfer: endPoint + 'v2/voucher-payments/:companyId/internal-transfer/:id',
    autoAddOldPayment: endPoint + 'voucherPayments/auto-add-old-payment',
  },
  voucherPaymentsBasket: {
    update: endPoint + 'voucherPaymentsBaskets/:basketUUID',
    getByBasketUUID: endPoint + 'voucherPaymentsBaskets/getByBasketUUID',
    getVoucherPaymentsByBasketUUID: endPoint + 'voucherPaymentsBaskets/getVoucherPaymentsByBasketUUID',
  },
  voucherPaymentsAuth: {
    add: endPoint + 'voucherPaymentsAuths',
    updateByBasketUUID: endPoint + 'voucherPaymentsAuths/updateByBasketUUID',
    getAuthStatusByBasketUUID: endPoint + 'voucherPaymentsAuths/getAuthStatusByBasketUUID',
  },
  voucherSuggestion: {
    update: endPoint + 'voucherSuggestions/:id',
  },
  transaction: {
    get: endPoint + 'v2/transactions/',
    fetchById: endPoint + 'v2/transactions/:id',
    add: endPoint + 'transactions',
    delete: endPoint + 'transactions/:id',
    getYears: endPoint + 'transactions/getYears',
    getStatistic: endPoint + 'transactions/getStatistic',
    getByMonth: endPoint + 'transactions/month',
    updateMissing: endPoint + 'transactions/missing',
    import: endPoint + 'transactions/import',
    importer: endPoint + 'v2/transactions/import-file-upload',
    readFileUpload: endPoint + 'v2/transactions/read-file-upload',
    importDataUpload: endPoint + 'v2/transactions/import-data-upload',
    updateStatus: endPoint + 'transactions/status',
    fetchAll: endPoint + 'transactions/fetchAll?',
    updateMissingByVoucher: endPoint + 'transactions/updateMissingByVoucher',
    updateMissingByAddVoucher: endPoint + 'transactions/updateMissingByAddVoucher',
    getMissingSummary: `${endPoint}transactions/getMissingSummary`,
    getSuggestTransaction: `${endPoint}transactions/getSuggestTransaction`,
    addList: endPoint + 'transactions/addList',
    autoUpdateTransaction: endPoint + 'transactions/autoUpdateTransaction',
    autoUpdateTransactionByAiLocal: endPoint + 'transactions/autoUpdateTransactionByAiLocal',
    getHistory: endPoint + 'transactions/get-history',
    downloadFilePdfPrivateLost: endPoint + 'transactions/export-pdf-private-lost',
  },
  transactionImport: {
    index: `${endPoint}bankImport`,
    delete: `${endPoint}bankImport/:importId`,
  },
  transactionVoucher: {
    add: `${endPoint}transactionVoucher`,
    adminVoucherConnect: `${endPoint}transactionVoucher/adminVoucherConnect`,
    refreshMatchingVoucherTransaction: `${endPoint}transactionVoucher/refreshMatchingVoucherTransaction`,
  },
  bank: {
    index: `${endPoint}banks`,
    adminAutoImport: `${endPoint}banks/admin-auto-import`,
    getBankBySwiftCode: `${endPoint}banks/bank/bySwiftCode`,
    addAutoImport: `${endPoint}banks/addAutoImport`,
    updateAutoImport: `${endPoint}banks/updateAutoImport`,
    updateStatus: `${endPoint}banks/updateStatus`,
    getByCompany: `${endPoint}banks/getByCompany`,
    getByFilter: `${endPoint}banks/getByFilter`,
    getByTemplate: `${endPoint}banks/getByTemplate`,
  },
  bankTemplate: {
    getLinkIntegration: `${endPoint}/v2/bank-templates/link-integration`,
    getAvailableRgbIntegration: `${endPoint}/v2/bank-templates/:companyId/available-rgb-integration`,
  },
  companyBank: {
    fetchAllCompanyBankInfoByCompanyId: `${endPoint}company-banks/fetch-all-company-bank-info-by-company-id`,
    getSettingBankAccount: `${endPoint}company-banks/get-setting-bank-accounts`,
    setDefaultAccountNumber: `${endPoint}company-banks/set-default-account-number`,
    getDefaultAccountNumber: `${endPoint}company-banks/get-default-bank`,
    deleteCompanyBank: `${endPoint}company-banks/:id`,
    sortingBankAccount: `${endPoint}company-banks/sorting-setting-bank-accounts`,
    getServiceDates: `${endPoint}v2/company-banks/:id/service-dates`,
  },
  companyBankService: {
    buyRemittance: `${endPoint}companyBankServices/buyRemittance`,
    addUpdate: `${endPoint}companyBankServices/add-update`,
  },
  authenticate: endPoint + 'auth',
  file: endPoint + 'file?file=',
  download: endPoint + 'file/download?file=',
  account: {
    checkLogin: `${endPointAccount}/check-login`,
    logout: `${endPointAccount}/logout`,
    getUserByToken: `${endPointAccount}/users/token/:token`,
    changeLanguage: `${endPointAccount}/language`,
  },
  service: {
    ehf: `${endPoint}company/services/ehf`,
    infor_ehf: `${endPoint}elma/participants/`,
    deleteEhf: `${endPoint}company/services/ehf/delete`,
    alert: `${endPoint}company/services/alert`,
    getOwner: `${endPoint}company/getOwner`,
    addCompanyUsers: `${endPoint}company/addCompanyUsers`,
    getCompanyDivisionByOrg: `${endPoint}company/get-company-division-by-org`,
    addCompanyDivisionByOrg: `${endPoint}company/add-company-division-by-org`,
    updateCompanyDivisionByOrg: `${endPoint}company/update-company-division-by-org`,
    deleteCompanyDivision: `${endPoint}company/delete-company-division`,
    getEHFAccessPointUrl: `${endPoint}company/services/ehf/get-access-point-url`,
  },
  inbox: {
    // get detail Inbox return url attachment
    computeData: `${endPoint}inbox/computeData`,
    readById: `${endPoint}inbox/readById/:id`,
  },
  // EHF SERVER
  ehf: {
    get: `${endPointEHF}/ehf`,
    listEndUserStatisticReports: `${endPointEHF}/v1/peppol-reporting/end-user-report`,
    generateEndUserStatisticReports: `${endPointEHF}/v1/peppol-reporting/end-user-report/generate`,
    sendEndUserStatisticReports: `${endPointEHF}/v1/peppol-reporting/end-user-report/send`,
    // transaction
    listTransactionReports: `${endPointEHF}/v1/peppol-reporting/transaction-report`,
    generateTransactionReports: `${endPointEHF}/v1/peppol-reporting/transaction-report/generate`,
    sendTransactionReports: `${endPointEHF}/v1/peppol-reporting/transaction-report/send`,
  },
  oryton: {
    getCompanyByUserId: `${endPointOryton}/api/subscriptions/products/1`, // 1 = Oryton
    fetchEmailForward: `${endpointMail}/api/mail-bilagsky/forward`,
    addEmailForward: `${endpointMail}/api/mail-bilagsky/forward`,
    removeEmailForward: `${endpointMail}/api/mail-bilagsky/forward/:uuid`,
    getInbox: `${endpointMail}/api/mail-bilagsky/inbox`,
    getInboxDetail: `${endpointMail}/api/mail-bilagsky/inbox/:uuid`,
    readInbox: `${endpointMail}/api/mail-bilagsky/inbox/:uuid`,
    listUsers: `${endPointOryton}/ajax/users/list`,
    listAccountants: `${endPointOryton}/ajax/users/types/accountants`,
    getMenuServices: `${endPointOryton}/ajax/settings/MenuService`,
    getCompanyStartEnd: `${endPointOryton}/api/companies/start_end`,
    getCompany: `${endPointOryton}/api/companies/:id/detail`,
  },
  // DNB SERVER
  dnb: {
    getBankBalance: bankEndPoint + '/api/dnb053/getBankBalance',
    getBankTransactionInfo: `${bankEndPoint}/api/dnb053/getBankTransactionInfo`,
    fetchAll: `${bankEndPoint}/api/dnb/fetchAll`,
  },
  sp1: {
    getBankBalance: bankEndPoint + '/api/sp1053/getBankBalance',
    getBankTransactionInfo: `${bankEndPoint}/api/sp1053/getBankTransactionInfo`,
    fetchAll: `${bankEndPoint}/api/sp1053/fetchAll`,
  },
  hsb: {
    getBankBalance: bankEndPoint + '/api/handelsbanken/get-bank-balance',
  },
  queue: {
    addForwardOldVoucher: `${endPoint}queue/addForwardOldVoucher`,
    lastTimeSendReminder: `${endPoint}queue/lastTimeSendReminder`,
  },
  ajaxServices: {
    fetchCompanyUsers: `${endPoint}ajaxServices/fetchCompanyUsers`,
  },
  globalRule: {
    add: `${endPoint}globalRules`,
    update: `${endPoint}globalRules/:id`,
    delete: `${endPoint}globalRules/:id`,
    fetchAll: `${endPoint}globalRules`,
  },
  localRule: {
    add: `${endPoint}localRules`,
    update: `${endPoint}localRules/:id`,
    delete: `${endPoint}localRules/:id`,
    fetchAll: `${endPoint}localRules`,
    copy: `${endPoint}localRules/copy`,
  },
  companyUsers: {
    add: `${endPoint}companyUsers`,
    fetchAll: `${endPoint}companyUsers`,
    delete: `${endPoint}companyUsers/:id`,
  },
  companyUsersReminder: {
    add: `${endPoint}companyUsersReminder`,
    update: `${endPoint}companyUsersReminder/:id`,
  },
  companyUsersAlertVoucher: {
    add: `${endPoint}companyUsersAlertVoucher`,
    update: `${endPoint}companyUsersAlertVoucher/:id`,
  },
  company: {
    getOrytonCompanies: `${endPoint}company/get-oryton-companies`,
    getRgbIntegration: `${endPoint}company/rgb-integration`,
    addRgbIntegration: `${endPoint}company/rgb-integration`,
    getOwnCompany: `${endPointOryton}/api/companies/products/1/own-company`,// 1 = Oryton,
  },
  companyService: {
    add: `${endPoint}company-services`,
    update: `${endPoint}company-services/:id`,
    fetchAll: `${endPoint}company-services`,
    paymentStatusV2: `${endPoint}company-services/:companyId/payment-status`,
    listActiveCompanyServices: `${endPoint}company-services/:companyId/list-active-services`,
    buyZtlService: `${endPoint}company-services/:companyId/buy-ztl-service`,
  },
  companyPaymentPackages: {
    add: `${endPoint}company-payment-packages`,
    update: `${endPoint}company-payment-packages/:id`,
    getByCompanyId: `${endPoint}company-payment-packages`,
  },
  users: {
    checkPassword: `${endPoint}users/check-password`,
    getRoleActions: `${endPointAccount}/action/bilagsky`,
  },
  companyUsersAssignPayment: {
    get: `${endPoint}company-users-assign-payment`,
    add: `${endPoint}company-users-assign-payment`,
    update: `${endPoint}company-users-assign-payment/:id`,
    getBanksVerifiedPayment: `${endPoint}company-users-assign-payment/banks-verified-payment/:assignUser`,
    addEditBanksVerifiedPayment: `${endPoint}company-users-assign-payment/banks-verified-payment/:assignUser`,
    fetchAssignUserByCompany: `${endPoint}/company-users-assign-payment/fetch-assign-users-by-company`,
  },
  paymentPackages: {
    fetchAll: `${endPoint}payment-packages`,
  },
  bankCheckList: {
    getBanksByCompany: `${endpointTrackingBank}/v1/bank-import/get-banks`,
    getResponsibleAccountants: `${endpointTrackingBank}/v1/bank-import/get-responsible`,
    getCompanies: `${endpointTrackingBank}/v1/bank-import/get-companies`,
    getAll: `${endpointTrackingBank}/v1/bank-import`,
    updateMonth: `${endpointTrackingBank}/v1/bank-import-month`,
    update: `${endpointTrackingBank}/v1/bank-import`,
    getYears: `${endpointTrackingBank}/v1/bank-import/get-years`,
    delete: `${endpointTrackingBank}/v1/bank-import/:id`,
    inititalData: `${endpointTrackingBank}/v1/bank-import/initial-data`,
    getCountCompanyMissing: `${endpointTrackingBank}/v1/bank-import/get-count-missing`,
    getCountNotUsingBilagsky: `${endpointTrackingBank}/v1/bank-import/get-count-companies-not-using-bilagsky`,
    getDataCompaniesNotUsingBilagsky: `${endpointTrackingBank}/v1/bank-import/get-companies-not-using-bilagsky`,
  },
  internalTransfer: {
    getList: `${endPoint}v2/voucher-payments/:companyId/internal-transfer`,
    getCompanyBank: `${endPoint}company-banks`,
    insertTransaction: `${endPoint}v2/voucher-payments/:companyId/internal-transfer`,
    getStatistic: `${endPoint}v2/voucher-payments/internal-transfer-statistic`,
  },
  voucherLabel: {
    getAll: `${endPoint}voucherLabels/:companyId`,
  },
  voucher2Label: {
    update: `${endPoint}voucher2Labels`,
  },
  transactionInfo: {
    get: `${bankEndPoint}/api/bank-template/get-transaction-info`,
  },
  statistic: {
    // payment
    getTotalPaymentByStatusForeachCompany: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-payment-statistic`,
    getCompaniesStatistic: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-list-company-statistic`,
    getSumPaymentByStatus: endpointPaymentStatistic + `/bilagsky-statistic/payment/sum-amount-payment`,
    getYearByPayment: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-years-by-payment`,
    getResponsibleAccountantPayment: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-responsible-payment`,
    getCounterPaymentFailed: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-counter-payment-failed-all-year`,
    getCountPaymentByFilter: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-count-payment-by-filter`,
    checkIsAccountantPaymentStatistic: endpointPaymentStatistic + `/bilagsky-statistic/payment/check-is-accountant-payment-statistic`,
    getAssignedFromPaymentStatistic: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-assigned-from-payment-statistic`,
    getUserAssignedPaymentStatistic: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-user-assigned-by-accountant-statistic`,
    getDataChartPaymentStatistic: endpointPaymentStatistic + `/bilagsky-statistic/payment/chart-payment-statistic`,
    // payment by supplier
    getResponsibleBySupplierHard: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-responsible-by-supplier`,
    getPaymentListBySupplierHard: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-payment-by-supplier-permanent`,
    getTotalAndSumBySuppplierHard: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-total-and-sum-by-supplier`,
    checkIsAccountantPaymentSupplier: endpointPaymentStatistic + `/bilagsky-statistic/payment/check-is-accountant-payment-supplier`,
    getAssignedFromPaymentSupplier: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-assigned-from-payment-supplier`,
    getUserAssignedPaymentSupplier: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-user-assigned-by-accountant-supplier`,
    getDataChartPaymentSupplier: endpointPaymentStatistic + `/bilagsky-statistic/payment/chart-payment-supplier`,
    getYearPaymentSupplier: endpointPaymentStatistic + `/bilagsky-statistic/payment/get-year-supplier`,
    // voucher
    getTotalVoucherMissing: endpointPaymentStatistic + `/bilagsky-statistic/voucher/get-voucher-unpaid-statistic`,
    getYearVoucherMissing: endpointPaymentStatistic + `/bilagsky-statistic/voucher/get-years`,
    getCompaniesVoucherMissing: endpointPaymentStatistic + `/bilagsky-statistic/voucher/get-companies`,
    voucherMissingGetResponsibleAccountant: endpointPaymentStatistic + `/bilagsky-statistic/voucher/responsile-accountants`,
    voucherMissingGetResponsibleAssigned: endpointPaymentStatistic + `/bilagsky-statistic/voucher/accoutants-assigned`,
    voucherMissingCheckIsAccountant: endpointPaymentStatistic + `/bilagsky-statistic/voucher/check-is-accountant`,
    getAssignedFromVoucher: endpointPaymentStatistic + `/bilagsky-statistic/voucher/get-assigned-from`,
    getUserAssignedByAccountantVoucher: endpointPaymentStatistic + `/bilagsky-statistic/voucher/get-user-assigned-by-accountant`,
    getDataChartVoucher: endpointPaymentStatistic + `/bilagsky-statistic/voucher/chart/overview`,
    getDataChartVoucherByCompanies: endpointPaymentStatistic + `/bilagsky-statistic/voucher/chart/companies`,
    // bank
    getBankMissing: endpointPaymentStatistic + `/bilagsky-statistic/bank/get-transaction-missing`,
    getYearBankMissing: endpointPaymentStatistic + `/bilagsky-statistic/bank/years`,
    getCompaniesBankMissing: endpointPaymentStatistic + `/bilagsky-statistic/bank/companies`,
    getResponsibleAccountantsBankMissing: endpointPaymentStatistic + `/bilagsky-statistic/bank/responsile-accountants`,
    getAccountantAssignedBankMissing: endpointPaymentStatistic + `/bilagsky-statistic/bank/accoutants-assigned`,
    bankMissingCheckIsAccountant: endpointPaymentStatistic + `/bilagsky-statistic/bank/check-is-accountant`,
    getAssignedFromBank: endpointPaymentStatistic + `/bilagsky-statistic/bank/get-assigned-from`,
    getUserAssignedByAccountantBank: endpointPaymentStatistic + `/bilagsky-statistic/bank/get-user-assigned-by-accountant`,
    // Altinn
    getYearsMVA: endpointPaymentStatistic + `/bilagsky-statistic/altinn/get-years`,
    getResponsibleAccountantsMVA: endpointPaymentStatistic + `/bilagsky-statistic/altinn/get-responsible-accountants`,
    getListMVA: endpointPaymentStatistic + `/bilagsky-statistic/altinn`,
    getCompaniesMVA: endpointPaymentStatistic + `/bilagsky-statistic/altinn/get-companies`,
    getDataChartBank: endpointPaymentStatistic + `/bilagsky-statistic/bank/chart/overview`,
    getDataChartBankByCompanies: endpointPaymentStatistic + `/bilagsky-statistic/bank/chart/companies`
  },
  ztl: {
    createConsent: endpointZtl + `/consent/`,
    fetchConsentError: endpointZtl + `/consent/:consentId/errors`,
    scaSuccess: endpointZtl + `/consent/sca-successfully`,
    fetchConsentByCompany: endpointZtl + `/consent`,
    getOwnerConsents: endpointZtl + `/consent/:companyId/owner-consents`,

    // accounts
    fetchAccountsByConsent: endpointZtl + `/consent/:consentId/bank-accounts`,
    renewConsent: endpointZtl + `/consent/:consentId/renew`,
    getAllBankTemplate: endpointZtl + `/consent/:companyId/get-ztl-bank-template`,
    getZtlAccountList: endpointZtl + `/ztl-accounts/list`,
    getConsentHistory: endpointZtl + `/consent/:consentId/histories`,
    // onboarding
    baseOnboarding: endpointZtl + `/onboarding/:companyId`,
    // update status by flowId
    updateStatusByFlowId: endpointZtl + `/onboarding/:companyId/status`,
    onboardingStatistic: endpointZtl + `/statistics/onboarding`,
    consentStatistic: endpointZtl + `/statistics/consent`,
    // notification
    getZtlNotificationByCompany: endpointZtl + `/notifications/:companyId/list`,
    readZtlNotification: endpointZtl + `/notifications/update/:id/readed`,
    markZtlNotificationAsRead: endpointZtl + `/notifications/:companyId/read-all`,
    // payment 
    paymentValidate: endpointZtl + `/ztl-payments/validates`,
    getAvailableDueDates: endpointZtl + `/ztl-payments/available-due-dates/:initiatingCountry`,
    // supports
    getSupportedCurrencies: endpointZtl + `/ztl-supports/currencies`,
    getSupportedCountries: endpointZtl + `/ztl-supports/country`,
    getSupportedCurrencyByCountry: endpointZtl + `/ztl-supports/country/:countryCode/paymentrequirement`,
  },
  bankServices: {
    getTransactionInfo: `${bankEndPoint}/api/bank-template/get-transaction-info`,
    getBalanceInfo: `${bankEndPoint}/api/bank-template/get-balance-info`,
  },
  voucherPaymentsV2: {
    payNow: endPoint + `v2/voucher-payments/pay-now`,
    scaSuccess: endPoint + `v2/voucher-payments/:voucherPaymentIds/sca-success`,
    fetchAll: endPoint + 'v2/voucher-payments',
    update: endPoint + 'v2/voucher-payments/:id',
    getStatistic: endPoint + 'v2/voucher-payments/statistics',
    addToPaymentList: endPoint + `v2/voucher-payments/add-to-paymentlist`,
    getByVoucherId: endPoint + 'v2/voucher-payments/get-by-voucherId',
    getByPaymentsUUID: endPoint + 'v2/voucher-payments/get-payment-info-by-uuid',
    delete: endPoint + `v2/voucher-payments/delete-list`,
    signing: endPoint + `v2/voucher-payments/:ids/signing`,
    addInternalTransfer: `${endPoint}v2/voucher-payments/internal-transfer`,
    editInternalTransfer: endPoint + 'v2/voucher-payments/internal-transfer/:id',
    exists: endPoint + `v2/voucher-payments/exists`,
    handleZtlScaCallback: endPoint + `v2/voucher-payments/:flowId/handle-ztl-sca-callback`,
    internaltionalPayNow: endPoint + `v2/voucher-payments/international-pay-now`,
    getLocalIbanInfo: endPoint + `v2/voucher-payments/local-iban-info`,
    cancelPayment: endPoint + `v2/voucher-payments/:id/cancel`,
  },
  companyBankV2: {
    getBankVerifyPayment: `${endPoint}v2/company-banks/:companyId/banks-verified-payment`,
    getBankBalances: `${endPoint}v2/company-banks/:companyId/balances`,
    getSettingBanks: `${endPoint}v2/company-banks/:companyId/setting-banks`,
    add: `${endPoint}v2/company-banks`,
    update: `${endPoint}v2/company-banks/:id`,
  },
  voucherV2: {
    getVoucherBalances: `${endPoint}v2/vouchers/:id/voucher-balances`,
    addVoucherBalances: `${endPoint}v2/vouchers/:id/add-balance`,
    removeVoucherBalances: `${endPoint}v2/vouchers/:id/remove-balance/:voucherBalanceId`,
  },
  statisticV2: {
    fetchAllStatisticPayments: `${endpointPaymentStatistic}/v2/statistics/payments`,
    getPaymentCompanies: `${endpointPaymentStatistic}/v2/statistics/payments/companies`,
    getPaymentYears: `${endpointPaymentStatistic}/v2/statistics/payments/years`,
    getResponsibleAccountants: `${endpointPaymentStatistic}/v2/statistics/company-info/responsible-accountants`,
    getAssignedUsers: `${endpointPaymentStatistic}/v2/statistics/company-assigned/assigned-users`,
    getTotalPaymentFailed: `${endpointPaymentStatistic}/v2/statistics/payments/failed`,
    fetchAllStatisticEcofinans: `${endpointPaymentStatistic}/v2/statistics/payments/eco-finans`,
  }
};
