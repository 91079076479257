import { api } from '../../constants/api.constant';
import { APIError } from '../../constants/errorCode/system';
import httpHelper from '../../helpers/httpHelper';
import BaseServices from '../baseServices';

export const companyBankServicesV2 = {
  getBankBalances: async (params) => {
    try {
      const { companyId } = params;
      const url = api.companyBankV2.getBankBalances.replace(':companyId', companyId);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getSettingBankAccount: async (params) => {
    try {
      const { companyId } = params;
      const paramString = httpHelper.serialize(params);
      const url = api.companyBankV2.getSettingBanks.replace(':companyId', companyId) + '?' + paramString;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  add: async (params) => {
    try {
      const url = api.companyBankV2.add;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async (params) => {
    try {
      const url = api.companyBankV2.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
