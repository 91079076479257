import React, { Suspense } from 'react';
// import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import Error404 from '../components/pages/Error404';
import SignCancel from '../components/sign/SignCancel';
import SignNext from '../components/sign/SignNext';
import LoadingBilagSky from '../components/UI/LoadingBilagSky';
import Video from '../components/video/Android';
import Invoice from '../components/video/Invoice';
import PaymentDNBAndroid from '../components/video/PaymentDNBAndroid';
import PaymentSP1Android from '../components/video/PaymentSP1Android';
import AddComment from '../components/webview/add-comment-task';
import EditComment from '../components/webview/edit-comment-task';
import EditDescription from '../components/webview/edit-description-task';
import ConsentSignant from '../components/ztl/sign/consent/signant';
import ZtlSignPayment from '../components/ztl/sign/payment';
import OnboardingCallback from '../components/ztl/sign/onboarding';
import { ErrorRoutes, RouterNoPrefix, RoutesUrl } from '../constants/routes.constants';
import { registerLocale } from 'react-datepicker';

const Routes = React.lazy(() => import('./Routes'));
// const AuthContainer = React.lazy(() => import('./AuthContainer'));
const PublicRoute = React.lazy(() => import('./PublicRoute'));

// const locales = {
//   en: {
//     months: [
//       "January", "February", "March", "April", "May", "June",
//       "July", "August", "September", "October", "November", "December"
//     ],
//     days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
//   },
//   no: {
//     localize: {
//       month: (index) => [
//         "januar", "februar", "mars", "april", "mai", "juni",
//         "juli", "august", "september", "oktober", "november", "desember"
//       ][index],
//       day: (index) => ["sø", "ma", "ti", "on", "to", "fr", "lø"][index]
//     },
//     formatLong: {
//       date: () => "dd.MM.yyyy",
//     },
//   }
// };

// registerLocale('no', locales.no.formatLong.date());

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<LoadingBilagSky />}>
          <Switch>
            <PublicRoute restricted={false} component={Video} path={RouterNoPrefix.VideoAndroid} exact />
            <PublicRoute restricted={false} component={EditDescription} path={RouterNoPrefix.EditDescriptionTask} exact />
            <PublicRoute restricted={false} component={AddComment} path={RouterNoPrefix.CreateCommentTask} exact />
            <PublicRoute restricted={false} component={EditComment} path={RouterNoPrefix.EditCommentTask} exact />
            <PublicRoute restricted={false} component={PaymentDNBAndroid} path={RouterNoPrefix.VideoPaymentDNBAndroid} exact />
            <PublicRoute restricted={false} component={PaymentSP1Android} path={RouterNoPrefix.VideoPaymentSP1Android} exact />
            <PublicRoute restricted={false} component={Invoice} path={RouterNoPrefix.Invoice} exact />
            <PublicRoute restricted={false} component={SignNext} path={RoutesUrl.SignNext} exact />
            <PublicRoute restricted={false} component={SignCancel} path={RoutesUrl.SignCancel} exact />
            <PublicRoute restricted={false} component={ConsentSignant} path={RouterNoPrefix.ConsentSignant} exact />
            <PublicRoute restricted={false} component={ZtlSignPayment} path={RouterNoPrefix.ZtlPaymentSign} exact />
            <PublicRoute restricted={false} component={OnboardingCallback} path={RouterNoPrefix.OnboardingCallback} exact />
            <PublicRoute restricted={false} component={Error404} path={ErrorRoutes.Notfound} exact />
            <Routes />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
