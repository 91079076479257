import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import Services from '../../../services/companyBankServices';
import bankServices from '../../../services/bankServices';
import { api } from '../../../constants/api.constant';

export default function* getBankBalanceByAccountNumberSaga() {
  yield takeEvery(actionTypes.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER, getBankBalanceByAccountNumber);
}

export function* getBankBalanceByAccountNumber(action) {
  try {
    // get company
    // 1- get years
    // dnb or sparebank1
    const { payload: params } = action;
    const { accountNumber, balanceType = 'CLBD', ibDate, ubDate } = params;
    const url = api.bankServices.getBalanceInfo;
    const objparams = { url, bankId: accountNumber, balanceType };
    if (ibDate) objparams.ibDate = ibDate;
    if (ubDate) objparams.ubDate = ubDate;

    const response = yield call(Services.getBankBalance, objparams);
    const { success, data } = response;
    if (success) {
      const result = data && data[0];
      yield put(actions.companyBank.getBankBalanceByAccountNumberSuccess(result));
    } else {
      yield put(actions.companyBank.getBankBalanceByAccountNumberFail(data));
    }
  } catch (error) {
    yield put(actions.companyBank.getBankBalanceByAccountNumberFail(error));
  }
}

export const getBankBalanceByAccountNumberAPI = async (params) => {
  const { accountNumber, balanceType = 'CLBD', ibDate, ubDate } = params;
  const url = api.bankServices.getBalanceInfo;
  const objparams = { url, bankId: accountNumber, balanceType };
  if (ibDate) objparams.ibDate = ibDate;
  if (ubDate) objparams.ubDate = ubDate;
  const response = await Services.getBankBalance(objparams);
  const { success, data } = response;
  if (success) {
    const result = data && data[0];
    return result;
  } else {
    return data;
  }
};
