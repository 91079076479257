import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import { bankNumber } from '../../../utils/Utils';
import { companyBankServicesV2 } from '../../../services/v2/companyBankServicesV2';

export default function* getBankAccounts() {
  yield takeEvery(actionTypes.SETTING_BANK.GET_SETTING_BANK_ACCOUNTS, requestBankAccounts);
}

export function* requestBankAccounts(action) {
  try {
    // update to api v2 to get accountantCode
    const response = yield call(companyBankServicesV2.getSettingBankAccount, action.payload);
    if (response.success) {
      const result = response.data.map((item) => ({
        ...item,
        ...item.Type && { Type: item.Type.toString() },
        ...item?.AccountNumber?.length === 11 && {
          AccountNumber: bankNumber(item.AccountNumber)
        }
      }));
      yield put(actions.settingBank.getBankAccountSuccess(result));
    }
    action?.callback && action?.callback();
  } catch (error) {
    action?.callback && action?.callback();
  }
}
